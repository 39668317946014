@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html{
        font-family: 'Roboto', sans-serif;
    }
}